import CardContainer from "../../components/UI/Common/Card/Card";
import Container from "../../components/UI/Common/Container";
import { Badge, Box, Button, CircularProgress, Tab, Tabs } from "@mui/material";
import Flex from "styled-flex-component";
import { MeliorTranslate } from "../../components/MeliorTranslate";
import { MeliorTable } from "../../components/MeliorTable";
import { useContext, useEffect, useState } from "react";
import {
  exportAnnotations,
  exportClassifications,
  fetchPagesForExport,
} from "../../api/export.api";
import ExportTable from "../../components/Export/Tables/Table";
import FileSaver from "file-saver";
import { toast } from "react-toastify";
import { FilterContext, FilterContextType } from "../../context/FilterContext";
import { useExportDocuments } from "../../hooks/useExportDocuments";
import { isEmpty } from "lodash";

const ExportPage = () => {
  const { showTestData, currTag } = useContext(
    FilterContext
  ) as FilterContextType;
  const [pageGroups, setPageGroups] = useState<any[]>([]);
  const [isLoadingPages, setIsLoadingPages] = useState(false);
  const [exportIds, setExportIds] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [listType, setListType] = useState("annotations");
  const [currList, setCurrList] = useState<any[]>([]);
  const showClassification = process.env.REACT_APP_ENVIRONMENT == "demo";
  const [page, setPage] = useState(1);
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);

  const params = {
    page: page,
    size: 100,
  };

  const { isLoading, documents, totalPages, refetch } =
    useExportDocuments(params);

  useEffect(() => {
    refetchList();
  }, []);

  useEffect(() => {
    const list = listType == "classifications" ? pageGroups : documents;

    const tagFilteredList =
      currTag && currTag?.id != "All"
        ? list?.filter(
            (doc) =>
              doc.tags &&
              doc.tags?.map((tag) => tag.id ?? tag.tag_id).includes(currTag.id)
          )
        : list;

    const filteredList = getToggledList(tagFilteredList);
    setCurrList(filteredList);
  }, [listType, pageGroups, documents, showTestData, currTag]);

  useEffect(() => {
    setExportIds([]);
  }, [listType, pageGroups, showTestData, currTag]);

  useEffect(() => {
    const unchecked = currList.find((item) => !exportIds.includes(item.doc_id));

    setIsAllChecked(isEmpty(unchecked));
  }, [exportIds, currList]);

  const getToggledList = (list) => {
    if (!list) return [];

    const temp: any = list.concat([]);

    const filterProperty = listType == "classifications" ? "doc_name" : "name";
    const filteredList = showTestData
      ? temp
      : temp.filter(
          (data) => !data[filterProperty].toLowerCase().includes("test")
        );

    return filteredList;
  };

  const refetchList = () => {
    getPagesForExport();
    getDocumentsForExport();
  };

  const getPagesForExport = () => {
    setIsLoadingPages(true);
    fetchPagesForExport().then((res) => {
      setPageGroups(res);
      setIsLoadingPages(false);
    });
  };

  const getDocumentsForExport = () => {
    refetch();
  };

  const doExport = () => {
    setIsSaving(true);

    if (listType == "classifications")
      exportClassifications(exportIds).then((res) => {
        if (res) {
          toast.success("Successfully exported classifications");
          FileSaver.saveAs(res);
          setExportIds([]);
          setIsSaving(false);
        }
      });
    else
      exportAnnotations(exportIds).then((res) => {
        if (res) {
          toast.success("Successfully exported annotations");
          FileSaver.saveAs(res);
          setExportIds([]);
          setIsSaving(false);
        }
      });
  };

  const handleSelectChange = (e) => {
    const checked = e.target.checked;

    const items = currList;
    const itemIds = items?.map((item) => item.doc_id);

    if (checked) {
      const filteredItemIds = itemIds.filter((id) => !exportIds.includes(id));
      setExportIds(exportIds.concat(filteredItemIds));
    } else {
      const filteredExportIds = exportIds.filter((id) => !itemIds.includes(id));
      setExportIds(filteredExportIds);
    }
  };

  return (
    <Container outerSpacing={1}>
      <CardContainer>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Flex justifyBetween alignCenter>
            <Tabs value={0} style={{ overflow: "visible" }}>
              <Tab
                label={
                  <Flex alignCenter>
                    <span>
                      <MeliorTranslate valueKey="Packet" />
                    </span>
                    <Container leftOuterSpacing={1} rightOuterSpacing={2}>
                      <Badge badgeContent={currList.length} color="primary" />
                    </Container>
                  </Flex>
                }
              />
            </Tabs>
            <Flex alignCenter style={{ gap: "20px", marginBottom: "10px" }}>
              {showClassification && (
                <div
                  style={{
                    background: "whitesmoke",
                    borderRadius: "5px",
                    border: "1px solid #3874CB",
                  }}
                >
                  <Button
                    variant={
                      listType == "classifications" ? "contained" : "text"
                    }
                    onClick={() => setListType("classifications")}
                  >
                    Classifications
                  </Button>
                  <Button
                    variant={listType == "annotations" ? "contained" : "text"}
                    onClick={() => setListType("annotations")}
                  >
                    Annotations
                  </Button>
                </div>
              )}
              <Badge
                badgeContent={isSaving ? 0 : exportIds.length}
                color="warning"
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    doExport();
                  }}
                  disabled={!exportIds.length || isSaving}
                  style={{ width: "140px" }}
                >
                  {isSaving ? (
                    <CircularProgress
                      style={{
                        color: "#3f51b5",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  ) : (
                    "Export"
                  )}
                </Button>
              </Badge>
            </Flex>
          </Flex>
        </Box>

        <MeliorTable
          showLoading={Boolean(
            listType == "classifications" ? isLoadingPages : isLoading
          )}
          error={""}
          items={currList}
          tableEl={
            <ExportTable
              items={currList}
              itemType={listType}
              exportIds={exportIds}
              setExportIds={(ids) => setExportIds(ids)}
              handleSelectChange={(e) => handleSelectChange(e)}
              setPage={setPage}
              totalPages={totalPages}
              page={page}
              isAllChecked={isAllChecked}
            />
          }
          loadingMessage={"Loading " + listType}
          emptyMessage={`No ${listType} to export`}
          errorMessage="An error has occurred"
        />
      </CardContainer>
    </Container>
  );
};

export default ExportPage;
