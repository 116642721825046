import CardContainer from "../../components/UI/Common/Card/Card";
import Container from "../../components/UI/Common/Container";
import { useContext, useEffect, useState } from "react";
import {
  createPage,
  getAssigned,
  getAssignedToOthers,
  getUnassignedDocuments,
  getUnassignedPages,
} from "../../api/files.api";
import { grabDocument, grabPage, unassignTask } from "../../api/grab.api";
import { FilterContext, FilterContextType } from "../../context/FilterContext";
import { getTags } from "../../api/tags.api";
import DocumentsProvider from "./context/documentsContext";
import TableCardHeader from "../../components/Documents/Tables/Documents/TableCardHeader";
import TableParent from "../../components/Documents/Tables/Documents/TableParent";

const DocumentsPage = () => {
  const { currTag, setAllTags } = useContext(
    FilterContext
  ) as FilterContextType;
  // Lists:
  const [backlogs, setBacklogs] = useState<any[]>([]);
  const [backlogsPages, setBacklogsPages] = useState<any[]>([]);
  const [assignedToOthersList, setAssignedToOthersList] = useState<any[]>([]);
  const [assignedList, setAssignedList] = useState<any[]>([]);
  const [assignedTotal, setAssignedTotal] = useState<number>(0);
  // Loading:
  const [isLoadingBacklogs, setIsLoadingBacklogs] = useState(false);
  const [isLoadingAssigned, setIsLoadingAssigned] = useState(false);
  const [isLoadingAssignedToOthers, setIsLoadingAssignedToOthers] =
    useState(false);
  const showClassification = process.env.REACT_APP_ENVIRONMENT == "demo";

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    refetch();
  }, [currTag]);

  const refetch = () => {
    fetchAssigned();
    fetchAssignedToOthers();
    fetchUnassignedDocs();
    if (showClassification) fetchUnassignedPages();
    getAllTags();
  };

  const getAllTags = () => {
    getTags()?.then((res) => {
      setAllTags(res);
    });
  };

  const fetchUnassignedDocs = () => {
    setIsLoadingBacklogs(true);
    getUnassignedDocuments(currTag).then((res) => {
      setBacklogs(res);
      setIsLoadingBacklogs(false);
    });
  };

  const fetchUnassignedPages = () => {
    setIsLoadingBacklogs(true);
    getUnassignedPages(currTag).then((res) => {
      setBacklogsPages(res);
      setIsLoadingBacklogs(false);
    });
  };

  const fetchAssigned = () => {
    setIsLoadingAssigned(true);
    getAssigned(currTag).then((res: any) => {
      setAssignedTotal(res.total);
      setAssignedList(res.transformed);
      setIsLoadingAssigned(false);
    });
  };

  const fetchAssignedToOthers = () => {
    setIsLoadingAssigned(true);
    getAssignedToOthers(currTag).then((res: any) => {
      // setAssignedToOthersTotal(res.total);
      setAssignedToOthersList(res.transformed);
      setIsLoadingAssignedToOthers(false);
    });
  };

  const grab = (doc_id, page_index, page_id) => {
    if (page_index) {
      if (page_id) {
        doGrabPage(doc_id, page_index, page_id);
      } else {
        createPage(doc_id, page_index).then((res) => {
          const page_id = res.page_id;
          doGrabPage(doc_id, page_index, page_id);
        });
      }
    } else {
      //grab document
      grabDocument(doc_id).then(() => {
        fetchAssigned();
      });
      //remove document from backlog
      setBacklogs(backlogs.filter((backlog) => backlog.doc_id != doc_id));
    }
  };

  const unassign = (assignment_id, parent_assignment_id) => {
    unassignTask(assignment_id).then(async () => {
      fetchUnassignedDocs();
      if (showClassification) fetchUnassignedPages();
    });

    if (parent_assignment_id) {
      // find parent doc
      const docIndex = assignedList.findIndex(
        (assigned) => assigned.assignment_id == parent_assignment_id
      );

      if (assignedList[docIndex].pages.length > 1) {
        // create a new list of pages without current page
        const updatedPages = assignedList[docIndex].pages.filter(
          (page) => page.assignment_id != assignment_id
        );

        //update parent doc's pages with new list
        const updatedList = assignedList.concat([]);
        updatedList[docIndex].pages = updatedPages;

        //update assigned list
        setAssignedList(updatedList);
      } else {
        setAssignedList(
          assignedList.filter(
            (assigned) => assigned.assignment_id != parent_assignment_id
          )
        );
      }
    } else {
      setAssignedList(
        assignedList.filter(
          (assigned) => assigned.assignment_id != assignment_id
        )
      );
    }
    setAssignedTotal(assignedTotal - 1);
  };

  const doGrabPage = (doc_id, page_index, page_id) => {
    grabPage(doc_id, page_id).then(() => {
      fetchAssigned();
    });
    // find parent doc
    const docIndex = backlogsPages.findIndex(
      (backlog) => backlog.doc_id == doc_id
    );
    if (backlogsPages[docIndex].pages.length > 1) {
      // create a new list of pages without current page
      const updatedPages = backlogsPages[docIndex].pages.filter(
        (page) => page.page_index != page_index
      );

      //update parent doc's pages with new list
      const updatedBacklogsPages = backlogsPages.concat([]);
      updatedBacklogsPages[docIndex].pages = updatedPages;

      //update backlogs pages
      setBacklogsPages(updatedBacklogsPages);
    } else {
      setBacklogsPages(
        backlogsPages.filter((backlog) => backlog.doc_id != doc_id)
      );
    }
  };

  return (
    <DocumentsProvider
      backlogs={backlogs}
      backlogsPages={backlogsPages}
      assignedList={assignedList}
      assignedToOthersList={assignedToOthersList}
      isLoadingBacklogs={isLoadingBacklogs}
      isLoadingAssigned={isLoadingAssigned}
      isLoadingAssignedToOthers={isLoadingAssignedToOthers}
    >
      <Container outerSpacing={1}>
        <CardContainer>
          <TableCardHeader
            showClassification={showClassification}
            refetch={refetch}
          />
          <TableParent refetch={refetch} grab={grab} unassign={unassign} />
        </CardContainer>
      </Container>
    </DocumentsProvider>
  );
};

export default DocumentsPage;
