import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import {
  Checkbox,
  Pagination,
  TableContainer,
  TableFooter,
  TableHead,
} from "@mui/material";
import ExportTableItem from "./Item";
import Flex from "styled-flex-component";

interface IExportTableProps {
  items: any[];
  itemType: string;
  exportIds: string[];
  setExportIds: (ids: string[]) => void;
  handleSelectChange: (e) => void;
  setPage: (page: number) => void;
  totalPages: number;
  page: number;
  isAllChecked: boolean;
}

export default function ExportTable({
  items,
  itemType,
  exportIds,
  setExportIds,
  handleSelectChange,
  setPage,
  totalPages,
  page,
  isAllChecked,
}: IExportTableProps) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <Flex
            alignCenter
            style={{
              height: "60px",
              borderBottom: "1px solid whitesmoke",
              fontWeight: "bold",
              width: "100%",
            }}
          >
            {/* Select Header */}
            <Flex
              justifyCenter
              style={{
                width: "3%",
              }}
            >
              <Checkbox
                checked={isAllChecked}
                indeterminate={
                  exportIds.length != 0 &&
                  exportIds.length != items.length &&
                  !isAllChecked
                }
                onClick={(e) => handleSelectChange(e)}
              />
            </Flex>
            {/* Packet ID */}
            <Flex
              style={{
                width: "30%",
              }}
            >
              Packet Name
            </Flex>
            {/* Tags */}
            <Flex justifyCenter style={{ width: "10%", minWidth: "210px" }}>
              Tags
            </Flex>
          </Flex>
        </TableHead>
        <TableBody>
          {items.map((item: any, index) => (
            <ExportTableItem
              key={index}
              item={item}
              exportIds={exportIds}
              setExportIds={(ids) => setExportIds(ids)}
              itemType={itemType}
            />
          ))}
        </TableBody>
        <TableFooter>
          <Flex justifyEnd>
            {itemType != "classifications" && (
              <Pagination
                onChange={(e, page) => setPage(page)}
                page={page}
                count={totalPages}
              />
            )}
          </Flex>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
