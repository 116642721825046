import {
  Button,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
} from "@mui/material";
import NavbarStyled from "./Navbar.styled";
import Flex from "styled-flex-component";
import { Text } from "../Typography";
import { supabase } from "../../../utils/supabase";
import { removeUserSessionData } from "../../../utils/userSession";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {
  FilterContext,
  FilterContextType,
} from "../../../context/FilterContext";
import { ITag } from "../../../@types/Tag";
import { Theme } from "../../../theme";
import TestDataToggle from "../TestDataToggle";
import { Clear } from "@mui/icons-material";

const Navbar = () => {
  const { currTag, setCurrTag, showTestData, setshowTestData, allTags } =
    useContext(FilterContext) as FilterContextType;
  const navigate = useNavigate();
  const location = useLocation();
  const [currPage, setCurrPage] = useState("Tasks");
  const [tags, setTags] = useState<ITag[]>([]);
  const showTestToggle = process.env.REACT_APP_ENVIRONMENT == "demo";

  useEffect(() => {
    setTags([{ id: "All", name: "All Tags", color: "" }].concat(allTags));
  }, [allTags]);

  const navButtons = [
    {
      text: "Tasks",
      onClick: () => {
        navigate("/");
      },
    },
    {
      text: "Feedback",
      onClick: () => {
        navigate("/feedback");
      },
    },
    {
      text: "Export",
      onClick: () => {
        navigate("/export");
      },
    },
    {
      text: "Log out",
      onClick: () => {
        logout();
      },
    },
  ];

  useEffect(() => {
    if (location.pathname.includes("feedback")) setCurrPage("Feedback");
    else if (location.pathname.includes("export")) setCurrPage("Export");
    else setCurrPage("Tasks");
  }, [location.pathname]);

  const logout = async () => {
    await supabase.auth.signOut();
    removeUserSessionData();
    navigate("/login");
  };

  const navButton = (text, onClick) => {
    return (
      <Button
        style={{
          color: currPage == text ? "#3874CB" : "gray",
          fontWeight: currPage == text ? 700 : 500,
        }}
        onClick={() => {
          onClick();
        }}
      >
        {text}
      </Button>
    );
  };

  return (
    <NavbarStyled>
      <Flex
        justifyBetween
        alignCenter
        style={{ paddingRight: "20px", paddingLeft: "20px" }}
      >
        <Flex alignCenter style={{ gap: "10px" }}>
          <Text
            customFontSize={1}
            customletterSpacing={1}
            color="#3874CB"
            customFontWeight={700}
          >
            LITH
          </Text>
        </Flex>
        <Flex style={{ gap: "20px" }}>
          <Select
            value={currTag?.id ?? "All"}
            onChange={(e) => {
              setCurrTag(tags.find((tag) => tag.id == e.target.value) ?? null);
            }}
            sx={{
              height: "40px",
              borderRadius: "20px",
              minWidth: "200px",
              paddingLeft: "5px",
              paddingTop: "5px",
              paddingBottom: "5px",
              color: Theme.primary,
            }}
            endAdornment={
              currTag?.id != "All" &&
              currTag?.id != null && (
                <InputAdornment sx={{ marginRight: "10px" }} position="end">
                  <IconButton
                    onClick={() => {
                      setCurrTag(tags.find((tag) => tag.id == "All") ?? null);
                    }}
                  >
                    <Clear />
                  </IconButton>
                </InputAdornment>
              )
            }
          >
            {tags.map((tag, index) => (
              <MenuItem
                key={index}
                value={tag.id}
                style={{ backgroundColor: tag.color, padding: "10px" }}
                sx={{ "&:hover": { opacity: "80%" } }}
              >
                <Text>{tag.name}</Text>
              </MenuItem>
            ))}
          </Select>
          {showTestToggle && (
            <TestDataToggle
              isShowingTestData={showTestData}
              setIsShowingTestData={setshowTestData}
            />
          )}
          <Divider orientation="vertical" variant="middle" flexItem />
          {navButtons.map((navItem) =>
            navButton(navItem.text, navItem.onClick)
          )}
        </Flex>
      </Flex>
    </NavbarStyled>
  );
};

export default Navbar;
