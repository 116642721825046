import {
  Label,
  LabelOff,
  PersonAddAlt1,
  PersonRemove,
} from "@mui/icons-material";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Flex from "styled-flex-component";
import {
  DocumentsContext,
  DocumentsContextType,
} from "../../../pages/Documents/context/documentsContext";
import { Text } from "../../UI/Typography";
import { Theme } from "../../../theme";
import { isEmpty } from "lodash";
import AddTagModal from "../AddTagModal";
import { createPage } from "../../../api/files.api";
import { grabDocument, grabPage, unassignTask } from "../../../api/grab.api";

interface IMultipleDocActionsProps {
  refetch: () => void;
}

const MultipleDocActions = ({ refetch }: IMultipleDocActionsProps) => {
  const { selectedDocumentIds, selectedDocuments, listType, backlogType } =
    useContext(DocumentsContext) as DocumentsContextType;
  const [isTagOpen, setIsTagOpen] = useState(false);
  const [taggingMode, setTaggingMode] = useState("");
  const [tags, setTags] = useState([]);
  const [isGrabbing, setIsGrabbing] = useState(false);
  const [isUnassigning, setIsUnassigning] = useState(false);
  const [pendingDocs, setPendingDocs] = useState<any[]>([]);

  useEffect(() => {
    if (isEmpty(selectedDocuments)) {
      setTags([]);
      setPendingDocs([]);
    }

    let selectedDocTags: any = [];
    selectedDocuments.forEach((selectedDoc) => {
      if (!isEmpty(selectedDoc.tags))
        selectedDoc.tags.forEach((tag) => {
          if (!selectedDocTags.map((tag) => tag.id).includes(tag.id))
            selectedDocTags = selectedDocTags.concat(tag);
        });
    });

    setTags(selectedDocTags);
    setPendingDocs(selectedDocuments.filter((doc) => doc.status == "pending"));
  }, [selectedDocuments]);

  const performGrab = () => {
    setIsGrabbing(true);
    selectedDocumentIds.forEach((docId, index) => {
      grab(docId, "", "", index == selectedDocumentIds.length - 1);
    });
  };

  const grab = (doc_id, page_index, page_id, isLast) => {
    if (page_index) {
      if (page_id) {
        doGrabPage(doc_id, page_id, isLast);
      } else {
        createPage(doc_id, page_index).then((res) => {
          const page_id = res.page_id;
          doGrabPage(doc_id, page_id, isLast);
        });
      }
    } else {
      grabDocument(doc_id).then(() => {
        if (isLast) finishGrab();
      });
    }
  };

  const doGrabPage = (doc_id, page_id, isLast) => {
    grabPage(doc_id, page_id).then(() => {
      if (isLast) finishGrab();
    });
  };

  const finishGrab = () => {
    refetch();
    setIsGrabbing(false);
  };

  const unassign = () => {
    setIsUnassigning(true);
    pendingDocs.forEach((document, index) => {
      if (document.status == "pending")
        unassignTask(document.assignment_id).then(() => {
          if (index == pendingDocs.length - 1) {
            refetch();
            setIsUnassigning(false);
          }
        });
    });
  };

  if (isEmpty(selectedDocumentIds)) return <></>;

  return (
    <>
      <Flex
        alignCenter
        justifyCenter
        style={{
          background: "whitesmoke",
          gap: "10px",
          marginBottom: "10px",
          borderRadius: "10px",
          paddingRight: 10,
          paddingLeft: 10,
        }}
      >
        {/* Counter - Selected documents */}
        <Tooltip title={`${selectedDocumentIds.length} selected documents`}>
          <div>
            <Text
              color="gray"
              style={{
                border: `1px solid gray`,
                borderRadius: "20px",
                height: "fit-content",
                paddingTop: "4px",
                paddingBottom: "4px",
                paddingRight: "9px",
                paddingLeft: "9px",
                cursor: "default",
              }}
            >
              {selectedDocumentIds.length}
            </Text>
          </div>
        </Tooltip>

        {/* Add Tags */}
        <IconButton
          sx={{ "&:hover": { color: Theme.primary } }}
          onClick={() => {
            setIsTagOpen(true);
            setTaggingMode("adding");
          }}
        >
          <Tooltip id="button-tag" title="Add tag">
            <Label />
          </Tooltip>
        </IconButton>

        {/* Remove Tags */}
        <IconButton
          sx={{ "&:hover": { color: Theme.primary } }}
          onClick={() => {
            setIsTagOpen(true);
            setTaggingMode("deleting");
          }}
        >
          <Tooltip id="button-tag" title="Remove tag">
            <LabelOff />
          </Tooltip>
        </IconButton>

        {/* Grab */}
        {listType == "Backlog" && backlogType == "Documents" && (
          <IconButton
            sx={{ "&:hover": { color: Theme.primary } }}
            onClick={() => {
              performGrab();
            }}
          >
            <Tooltip id="button-tag" title="Grab documents">
              {isGrabbing ? (
                <CircularProgress
                  style={{
                    color: "gray",
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <PersonAddAlt1 />
              )}
            </Tooltip>
          </IconButton>
        )}

        {/* Unassign */}
        {listType == "Assigned to you" && !isEmpty(pendingDocs) && (
          <IconButton
            sx={{ "&:hover": { color: Theme.primary } }}
            onClick={() => {
              unassign();
            }}
          >
            <Tooltip id="button-tag" title="Unassign documents">
              {isUnassigning ? (
                <CircularProgress
                  style={{
                    color: "gray",
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <PersonRemove />
              )}
            </Tooltip>
          </IconButton>
        )}
      </Flex>

      <AddTagModal
        documentTags={tags}
        documentIds={selectedDocumentIds}
        isOpen={isTagOpen}
        setIsOpen={setIsTagOpen}
        refetch={() => refetch()}
        mode={taggingMode}
      />
    </>
  );
};
export default MultipleDocActions;
