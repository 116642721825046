import { Text } from "../../UI/Typography";
import moment from "moment";
import Flex from "styled-flex-component";
import GrabButton from "../GrabButton";
import UnassignButton from "../UnassignButton";
import ClassifyButton from "../ClassifyButton";
import AnnotateButton from "../AnnotateButton";
import SubmitButton from "../SubmitButton";
import ViewButton from "../ViewButton";
import { Link, Tooltip } from "@mui/material";
import { capitalize } from "lodash";
import AddTagModal from "../AddTagModal";
import { useState } from "react";
import DocumentTableItemTags from "../DocumentTableItemTags";
import PacketType from "../TableColumn/PacketType";
import ItemCheckbox from "../Checkbox";

interface Props {
  document: any; // TODO: Add interface
  isUnassigned: boolean;
  isAssignedToOthers: boolean;
  savingId: string;
  grab: (doc_id: string, page_index: string, page_id: string) => void;
  unassign: (assignment_id: string, parent_assignment_id: string) => void;
  setSavingId: (id: string) => void;
  refetch: () => void;
  updateStatus: (assignment_id: string) => void;
}

export default function DocItem({
  document,
  isUnassigned,
  isAssignedToOthers,
  savingId,
  grab,
  unassign,
  setSavingId,
  refetch,
  updateStatus,
}: Props) {
  const [isTagOpen, setIsTagOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const showClassification = process.env.REACT_APP_ENVIRONMENT == "demo";

  return (
    <Flex
      alignCenter
      style={{
        paddingTop: "5px",
        paddingBottom: "5px",
        width: "100%",
      }}
    >
      <Flex
        justifyCenter
        style={{
          width: "3%",
        }}
      >
        <ItemCheckbox
          document={document}
          isHovered={isHovered}
          setIsHovered={setIsHovered}
          isAll={false}
        />
      </Flex>
      {/* Packet ID */}
      <div
        style={{
          width: "17%",
          minWidth: "200px",
          display: "flex",
        }}
      >
        <Link href={`insights-readonly/${document.doc_id}`}>
          {document.doc_name ?? document.doc_id}
        </Link>
      </div>
      {/* Packet Type */}
      <PacketType document={document} isUnassigned={isUnassigned} />
      {/* Date Updated */}
      <Flex
        justifyCenter
        style={{
          width: "10%",
          minWidth: "50px",
        }}
      >
        <Text>{moment(document.created_at).format("lll")} </Text>
      </Flex>
      {/* Status */}
      <Flex
        justifyCenter
        style={{
          width: "10%",
          minWidth: "50px",
          color:
            document.status != "pending" && !isUnassigned ? "green" : "black",
        }}
      >
        <Text style={{ textAlign: "center" }}>
          {isUnassigned
            ? `For Annotation ${!document.doc_type ? "& Classification" : ""}`
            : capitalize(
                document.status == "classified_annotated"
                  ? "annotated"
                  : document.status
              )}
        </Text>
      </Flex>
      {/* Assignee */}
      {isAssignedToOthers && (
        <Flex justifyCenter style={{ width: "15%", minWidth: "50px" }}>
          <Tooltip title={document.user_id}>
            <div
              style={{
                width: "60%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textWrap: "nowrap",
              }}
            >
              <Text>{document.user_id}</Text>
            </div>
          </Tooltip>
        </Flex>
      )}
      {/*  Tags */}
      <Flex
        justifyCenter
        style={{
          width: "10%",
          minWidth: "210px",
        }}
      >
        <DocumentTableItemTags
          document={document}
          docTags={document.tags}
          addTag={() => {
            setIsTagOpen(true);
          }}
          isViewOnly={false}
        />
      </Flex>
      {/* Actions */}
      {!isAssignedToOthers ? (
        <Flex justifyEnd style={{ width: "35%" }}>
          {isUnassigned ? (
            <GrabButton grab={grab} document={document} />
          ) : (
            <Flex justifyEnd style={{ gap: "10px" }}>
              {/* Unassign */}
              {document.status == "pending" && (
                <UnassignButton unassign={unassign} document={document} />
              )}
              {/* Classify */}
              <ClassifyButton
                document={document}
                isShow={document.document_type == null && showClassification}
                started={document.document_type != null}
              />
              {/* Annotate */}
              <AnnotateButton document={document} />
              {/* Submit */}
              {document.status != "pending" && (
                <SubmitButton
                  assignment_id={document.assignment_id}
                  savingId={savingId}
                  setSavingId={setSavingId}
                  refetch={refetch}
                  updateStatus={updateStatus}
                />
              )}
            </Flex>
          )}
        </Flex>
      ) : (
        <Flex justifyEnd style={{ width: "20%" }}>
          <ViewButton document={document} />
        </Flex>
      )}
      <AddTagModal
        documentTags={document.tags}
        documentIds={[document.doc_id]}
        isOpen={isTagOpen}
        setIsOpen={setIsTagOpen}
        refetch={refetch}
        mode={document.tags?.length ? "updating" : "adding"}
      />
    </Flex>
  );
}
