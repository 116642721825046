import React, { createContext, useState } from "react";

export type DocumentsContextType = {
  currList: any[];
  backlogs: any[];
  backlogsPages: any[];
  assignedList: any[];
  assignedToOthersList: any[];
  isLoadingBacklogs: boolean;
  isLoadingAssigned: boolean;
  isLoadingAssignedToOthers: boolean;
  listType: string;
  backlogType: string;
  selectedDocumentIds: string[];
  selectedDocuments: any[];
  isLoading: boolean;
  emptyMsg: string;
  setCurrList: (list: any[]) => void;
  setListType: (type: string) => void;
  setBacklogType: (type: string) => void;
  setSelectedDocumentIds: (doc: string[]) => void;
  setIsLoading: (isLoading: boolean) => void;
  setEmptyMsg: (emptyMsg: string) => void;
  setSelectedDocuments: (docs: any[]) => void;
};

export const DocumentsContext = createContext<DocumentsContextType | null>(
  null
);

interface DocumentsProviderProps {
  children: React.ReactNode;
  backlogs: any[];
  backlogsPages: any[];
  assignedList: any[];
  assignedToOthersList: any[];
  isLoadingBacklogs: boolean;
  isLoadingAssigned: boolean;
  isLoadingAssignedToOthers: boolean;
}

const DocumentsProvider = ({
  children,
  backlogs,
  backlogsPages,
  assignedList,
  assignedToOthersList,
  isLoadingBacklogs,
  isLoadingAssigned,
  isLoadingAssignedToOthers,
}: DocumentsProviderProps) => {
  const [listType, setListType] = useState<string>("Assigned to you");
  const [backlogType, setBacklogType] = useState<string>("Documents");
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emptyMsg, setEmptyMsg] = useState<string>("");
  const [currList, setCurrList] = useState<any[]>([]);

  return (
    <DocumentsContext.Provider
      value={{
        currList,
        backlogs,
        backlogsPages,
        assignedList,
        assignedToOthersList,
        isLoadingBacklogs,
        isLoadingAssigned,
        isLoadingAssignedToOthers,
        listType,
        backlogType,
        selectedDocumentIds,
        selectedDocuments,
        isLoading,
        emptyMsg,
        setCurrList,
        setListType,
        setBacklogType,
        setSelectedDocumentIds,
        setSelectedDocuments,
        setIsLoading,
        setEmptyMsg,
      }}
    >
      {children}
    </DocumentsContext.Provider>
  );
};

export default DocumentsProvider;
