import { useQuery } from "@tanstack/react-query";
import { fetchDocumentsForExport } from "../api/export.api";

export function useExportDocuments(params) {
  const { data, ...rest } = useQuery({
    queryFn: () => fetchDocumentsForExport(params).then((res) => res),
    queryKey: ["documents", params],
    refetchOnWindowFocus: false,
  });

  return {
    documents: data?.items,
    totalPages: data?.pages,
    ...rest,
  };
}
