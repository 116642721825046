import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { TableContainer, TableHead } from "@mui/material";
import { IDocument } from "../../../../@types/Document";
import DocumentsTableItem from "./Item";
import Flex from "styled-flex-component";
import { useContext, useEffect, useState } from "react";
import ItemCheckbox from "../../Checkbox";
import {
  DocumentsContext,
  DocumentsContextType,
} from "../../../../pages/Documents/context/documentsContext";

interface IDocumentsTableProps {
  refetch: () => void;
  grab: (doc_id: string, page_index: string, page_id: string) => void;
  unassign: (assignment_id: string, parent_assignment_id: string) => void;
}

const DocumentsTable = ({ grab, unassign, refetch }: IDocumentsTableProps) => {
  const { currList, selectedDocumentIds, setSelectedDocumentIds, listType } =
    useContext(DocumentsContext) as DocumentsContextType;
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setSelectedDocumentIds([]);
  }, [currList]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <Flex
            alignCenter
            style={{
              height: "60px",
              borderBottom: "1px solid whitesmoke",
              fontWeight: "bold",
              width: "100%",
            }}
          >
            {/* Select Header */}
            <Flex
              justifyCenter
              style={{
                width: "3%",
              }}
            >
              <ItemCheckbox
                document={null}
                isIndeterminate={
                  selectedDocumentIds.length > 0 &&
                  selectedDocumentIds.length != currList.length
                }
                isHovered={isHovered}
                setIsHovered={setIsHovered}
                isAll={true}
              />
            </Flex>
            {/* Packet ID */}
            <Flex
              style={{
                width: "17%",
              }}
            >
              Packet Name
            </Flex>
            {/* Packet Type */}
            <Flex
              justifyCenter
              style={{
                width: "14%",
                textAlign: "center",
              }}
            >
              Packet Type
            </Flex>
            {/* Date Uploaded */}
            <Flex
              justifyCenter
              style={{
                width: "10%",
                textAlign: "center",
              }}
            >
              Date Uploaded
            </Flex>
            {/* Status */}
            <Flex justifyCenter style={{ width: "10%" }}>
              Status
            </Flex>
            {/* Assignee */}
            {listType == "Assigned to others" && (
              <Flex justifyCenter style={{ width: "15%" }}>
                Assignee
              </Flex>
            )}
            {/* Tags */}
            <Flex justifyCenter style={{ width: "10%", minWidth: "210px" }}>
              Tags
            </Flex>
            {/* Actions */}
            <Flex
              justifyCenter
              style={{
                width: listType == "Assigned to others" ? "20%" : "35%",
              }}
            >
              Actions
            </Flex>
          </Flex>
        </TableHead>
        <TableBody>
          {currList.map((document: IDocument, index) => (
            <DocumentsTableItem
              key={index}
              document={document}
              index={index}
              grab={(doc_id, page_index, page_id) =>
                grab(doc_id, page_index, page_id)
              }
              unassign={(assignment_id, parent_assignment_id) =>
                unassign(assignment_id, parent_assignment_id)
              }
              isUnassigned={listType == "Backlog"}
              isAssignedToOthers={listType == "Assigned to others"}
              refetch={() => refetch()}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DocumentsTable;
