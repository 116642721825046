import { Checkbox } from "@mui/material";
import { Theme } from "../../../theme";
import { useContext } from "react";
import {
  DocumentsContext,
  DocumentsContextType,
} from "../../../pages/Documents/context/documentsContext";

interface IItemCheckboxProps {
  document: any;
  isIndeterminate?: boolean;
  isHovered: boolean;
  isAll: boolean;
  setIsHovered: (isHovered: boolean) => void;
}

const ItemCheckbox = ({
  document,
  isIndeterminate,
  isHovered,
  isAll,
  setIsHovered,
}: IItemCheckboxProps) => {
  const {
    currList,
    selectedDocumentIds,
    selectedDocuments,
    setSelectedDocumentIds,
    setSelectedDocuments,
  } = useContext(DocumentsContext) as DocumentsContextType;

  const handleSelectChange = (event) => {
    const checked = event.target.checked;
    const docIds = isAll
      ? checked
        ? currList.map((item) => item.doc_id)
        : []
      : checked
        ? selectedDocumentIds.concat(document.doc_id)
        : selectedDocumentIds.filter((docId) => docId != document?.doc_id);
    const docs = isAll
      ? checked
        ? currList
        : []
      : checked
        ? selectedDocuments.concat(document)
        : selectedDocuments.filter((doc) => doc.doc_id != document?.doc_id);

    setSelectedDocuments(docs);
    setSelectedDocumentIds(docIds);
  };

  return (
    <Checkbox
      checked={
        isAll
          ? selectedDocumentIds.length == currList.length
          : Boolean(
              selectedDocumentIds?.find((doc) => doc === document?.doc_id)
            )
      }
      indeterminate={isIndeterminate}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={(event) => handleSelectChange(event)}
      style={{
        color:
          isHovered ||
          Boolean(selectedDocumentIds?.find((doc) => doc === document?.doc_id))
            ? Theme.primary
            : "lightgray",
        padding: 0,
        paddingRight: 10,
      }}
    />
  );
};
export default ItemCheckbox;
