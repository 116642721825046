import { useContext, useEffect } from "react";
import {
  DocumentsContext,
  DocumentsContextType,
} from "../../../../pages/Documents/context/documentsContext";
import { MeliorTable } from "../../../MeliorTable";
import DocumentsTable from "./Table";

interface ITableParentProps {
  refetch: () => void;
  grab: (doc_id: string, page_index: string, page_id: string) => void;
  unassign: (assignment_id: string, parent_assignment_id: string) => void;
}

const TableParent = ({ grab, unassign, refetch }: ITableParentProps) => {
  const { currList, isLoading, setSelectedDocumentIds, emptyMsg } = useContext(
    DocumentsContext
  ) as DocumentsContextType;

  useEffect(() => {
    setSelectedDocumentIds([]);
  }, [currList]);

  return (
    <MeliorTable
      showLoading={isLoading}
      error={""}
      items={currList}
      tableEl={
        <DocumentsTable
          refetch={() => refetch()}
          grab={(doc_id, page_id, isExisting) =>
            grab(doc_id, page_id, isExisting)
          }
          unassign={(assignment_id, parent_assignment_id) =>
            unassign(assignment_id, parent_assignment_id)
          }
        />
      }
      loadingMessage="Loading items"
      emptyMessage={emptyMsg}
      errorMessage="An error has occurred"
    />
  );
};

export default TableParent;
