import Flex from "styled-flex-component";
import { Text } from "../../UI/Typography";
import { useContext } from "react";
import {
  InsightsReadOnlyContext,
  InsightsReadOnlyContextType,
} from "../../../pages/InsightsReadOnlyPage/context/insightsReadOnlyContext";
import { Theme } from "../../../theme";

interface ISuggestedAnswerProps {
  clause: any;
}

const SuggestedAnswer = ({ clause }: ISuggestedAnswerProps) => {
  const { textMatchIndex } = useContext(
    InsightsReadOnlyContext
  ) as InsightsReadOnlyContextType;

  return (
    <Flex
      column
      style={{
        gap: "10px",
        background:
          clause.answers[textMatchIndex].suggested_answer ==
          clause.answers[textMatchIndex].text
            ? Theme.lightgreen
            : "lightyellow",
        padding: "10px",
      }}
    >
      {/* Subheader - Suggested answer */}
      <Text customFontSize={0.8} color="gray" customFontWeight={800}>
        Suggested answer:
      </Text>
      {/* Suggested answer */}
      <Text customFontSize={0.8} color="gray">
        {clause.answers[textMatchIndex].suggested_answer}
      </Text>
      {/* Subheader - Confidence + value */}
      <Text customFontSize={0.8} color="gray" customFontWeight={800}>
        Confidence:
      </Text>
      {/* Confience */}
      <Text customFontSize={0.8} color="gray">
        {clause.answers[textMatchIndex].confidence}
      </Text>
      {/* Subheader - Confidence explanation */}
      <Text customFontSize={0.8} color="gray" customFontWeight={800}>
        Confidence explanation:
      </Text>
      {/* Confience explanation */}
      <Text customFontSize={0.8} color="gray">
        {clause.answers[textMatchIndex].confidence_explanation}
      </Text>
    </Flex>
  );
};

export default SuggestedAnswer;
