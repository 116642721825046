import { Box, Button, CircularProgress, Tab, Tabs } from "@mui/material";
import MultipleDocActions from "../../MultipleDocActions";
import { useContext, useEffect, useState } from "react";
import { Text } from "../../../UI/Typography";
import Flex from "styled-flex-component";
import { Theme } from "../../../../theme";
import { isEmpty } from "lodash";
import {
  DocumentsContext,
  DocumentsContextType,
} from "../../../../pages/Documents/context/documentsContext";
import {
  FilterContext,
  FilterContextType,
} from "../../../../context/FilterContext";

interface ITableCardHeaderProps {
  showClassification: boolean;
  refetch: () => void;
}

const TableCardHeader = ({
  showClassification,
  refetch,
}: ITableCardHeaderProps) => {
  const {
    listType,
    backlogType,
    isLoadingAssigned,
    isLoadingAssignedToOthers,
    isLoadingBacklogs,
    backlogs,
    backlogsPages,
    assignedList,
    assignedToOthersList,
    setListType,
    setBacklogType,
    setIsLoading,
    setEmptyMsg,
    setCurrList,
  } = useContext(DocumentsContext) as DocumentsContextType;
  const { showTestData } = useContext(FilterContext) as FilterContextType;
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (listType == "Assigned to you") {
      setIsLoading(isLoadingAssigned && isEmpty(assignedList));
      setEmptyMsg("No assigned items. Grab from Backlog.");
    } else if (listType == "Assigned to others") {
      setIsLoading(isLoadingAssignedToOthers && isEmpty(assignedToOthersList));
      setEmptyMsg("No assigned items to others.");
    } else if (listType == "Backlogs") {
      setIsLoading(
        isLoadingBacklogs && (isEmpty(backlogs) || isEmpty(backlogsPages))
      );
      setEmptyMsg("No items to work on");
    } else setIsLoading(false);
  }, [listType, backlogType]);

  useEffect(() => {
    updateList();
  }, [
    listType,
    backlogType,
    backlogs,
    backlogsPages,
    assignedList,
    assignedToOthersList,
    showTestData,
  ]);

  const updateList = () => {
    const list = getCurrentList();
    const newList = getToggledList(list);
    setCurrList(newList);
    setCounter(newList.length);
  };

  const getCurrentList = () => {
    return listType == "Backlog"
      ? backlogType == "Documents"
        ? backlogs
        : backlogsPages
      : listType == "Assigned to you"
        ? assignedList
        : assignedToOthersList;
  };

  const getToggledList = (list) => {
    const temp: any = list.concat([]);
    return showTestData
      ? temp
      : temp.filter((doc) => !doc.doc_name.toLowerCase().includes("test"));
  };

  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: "divider",
      }}
    >
      <Flex justifyBetween alignCenter>
        {/* Tabs */}
        <Flex alignCenter style={{ gap: "5px" }}>
          {/* Tabs */}
          {listType == "Backlog" && (
            <Tabs value={backlogType} style={{ overflow: "visible" }}>
              <Tab
                label="Documents"
                value="Documents"
                onClick={() => setBacklogType("Documents")}
              />
              {showClassification && (
                <Tab
                  label="Pages"
                  value="Pages"
                  onClick={() => setBacklogType("Pages")}
                />
              )}
            </Tabs>
          )}
          {/* Counter */}
          <Text
            color="white"
            style={{
              background: Theme.primary,
              borderRadius: "20px",
              height: "fit-content",
              paddingTop: "4px",
              paddingBottom: "4px",
              paddingRight: "9px",
              paddingLeft: "9px",
              marginRight: "15px",
            }}
          >
            {counter}
          </Text>
        </Flex>
        <Flex style={{ gap: "20px" }}>
          {/* Multi-actions */}
          <MultipleDocActions refetch={refetch} />
          {/* Ticket Types */}
          <Flex alignCenter style={{ gap: "20px", marginBottom: "10px" }}>
            {Boolean(
              listType == "Backlog"
                ? isLoadingBacklogs && backlogs.length && backlogsPages.length
                : listType == "Assigned to you"
                  ? isLoadingAssigned && assignedList.length
                  : isLoadingAssignedToOthers && assignedToOthersList.length
            ) && (
              <CircularProgress
                style={{
                  color: "#3f51b5",
                  width: "20px",
                  height: "20px",
                }}
              />
            )}
            <div
              style={{
                background: "whitesmoke",
                borderRadius: "5px",
                border: "1px solid #3874CB",
              }}
            >
              <Button
                variant={listType == "Backlog" ? "contained" : "text"}
                onClick={() => setListType("Backlog")}
                style={{
                  borderRadius: "0px",
                }}
              >
                Backlog
              </Button>
              <Button
                variant={
                  listType == "Assigned to others" ? "contained" : "text"
                }
                onClick={() => setListType("Assigned to others")}
                style={{
                  borderRight: "1px solid",
                  borderLeft: "1px solid",
                  borderColor: "#3874CB",
                  borderRadius: "0px",
                }}
              >
                Assigned to others
              </Button>
              <Button
                variant={listType == "Assigned to you" ? "contained" : "text"}
                onClick={() => setListType("Assigned to you")}
                style={{
                  borderRadius: "0px",
                }}
              >
                Assigned to you
              </Button>
            </div>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default TableCardHeader;
